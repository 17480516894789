import {atom} from "recoil";
import {useQuery} from "react-query";
import {query as _query} from "firebase/firestore";

import {colName, crud} from "./user.logs.business";
import {useEntityCount} from "../../../../shared/hooks/hooks.utils";
import {auditorCreate, execQueryForList} from "../../../../shared/services/firebase/db.firebase";
import {
    initialSearchQueryData,
    useInvalidateQueriesByRootName,
    useSearchDocs
} from "../../../../shared/services/firebase/firebase.cache.store";


export const {colRef, docRef, create, update, remove, findById, findAll, count} = crud;

const departuresState = atom({
    key: "user.logs.state",
    default: {...initialSearchQueryData},
});

export const useSearchUserLogs = () => useSearchDocs({
    colRef, state: departuresState, queryRootName: colName, queryName: "search"
});

export const useFindById = (id) => {
    const queryKey = [colName, "by.id", id];
    const queryFn = () => {
        if (!id) return null;
        return findById(id);
    }
    return useQuery(queryKey, queryFn);
}

export const useUserLogCount = () => useEntityCount("USER_LOG", count);

export const useFindAllUserLogs = () => {
    const queryKey = [colName, "all"];
    const queryFn = async () => await execQueryForList(_query(colRef));
    return useQuery(queryKey, queryFn, {});
}

export const createLog = async (log) => {
    try {
        await create(auditorCreate(log));
    } catch (e) {
        console.log(e)
    }
}

export const useInvalidateQueries = () => useInvalidateQueriesByRootName(colName);

