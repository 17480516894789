import {UserLogs} from "./user.logs.domains";
import {auditorCreate, crudService} from "../../../../shared/services/firebase/db.firebase";

export const colName = UserLogs.colName;

export const crud = crudService(colName);

export const createLog = async (log) => {
    try {
        await create(auditorCreate(log));
    } catch (e) {
        console.log(e)
    }
}
