import {useCallback, useEffect, useMemo} from "react";
import {useQuery} from "react-query";
import {atom, useRecoilState} from "recoil";

import {ko, ok} from "../../../../utils/service.utils";
import {Event, TicketOfferStates} from "./event.domains";
import {dateToDDMMYYYY} from "../../../../utils/formatTime";
import {_updateField} from "../../../payment/payment.service";
import {LightMode, LightModeTwoTone} from "@mui/icons-material";
import {createLog} from "../../admin/user-logs/user.logs.service";
import {query as _query, setDoc, where} from "firebase/firestore";
import {useEntityCount} from "../../../../shared/hooks/hooks.utils";
import {uploadFile, uploadFiles} from "../../../../shared/services/firebase/storage.firebase";
import {useInvalidateQueriesByRootName} from "../../../../shared/services/firebase/firebase.cache.store";

import {auditorCreate, collectionGroupRef, crudService, documentRef,
    execQueryForList, findByRef, runTransaction, service} from "../../../../shared/services/firebase/db.firebase";
import {Enum} from "../../../../utils/enum.utils";

export const EventTime = Enum({
    MORNING: {label: "Matin", morning: true, Icon: LightMode},
    AFTERNOON: {label: "Après Midi", afternoon: true, Icon: LightModeTwoTone},
});

const pendingService = crudService(Event.colNamePending);
const validatedService = crudService(Event.colNameValidated);

export const validatedService2 = service(Event.colNameValidated);

const eventsValidatedState = atom({
    key: "events.state",
    default: {events: {}, departure: null, day: null, time: null, availableDays: []},
});

const backofficePendingEventState = atom({
    key: "backoffice.pending.event.state",
    default: {events: {}, departure: null, day: null, time: null, availableDays: []},
});
const backofficeValidatedEventState = atom({
    key: "backoffice.validated.event.state",
    default: {events: {}, departure: null, day: null, time: null, availableDays: []},
});
const backofficePastEventState = atom({
    key: "backoffice.past.event.state",
    default: {events: {}, departure: null, day: null, time: null, availableDays: []},
});


export const useEventCount = () => {
    return useEntityCount("EVENT", validatedService.count);
}

export const useSearchValidatedEvents = () => {
    const [queryData, setQueryData] = useRecoilState(eventsValidatedState);
    const {departure, day, time, events} = queryData;

    const queryKey = [Event.colNameValidated, "search", departure];

    const queryFn = async () => {
        if (!departure) return [];

        const fetchQuery = _query(
            validatedService.colRef,
            where(Event.departure, "==", departure.id),
            where(Event.startingDate, ">=", new Date())
        );

        // fetchQuery = _query(fetchQuery, where(Event.remainingSeatCount, ">", 0));

        return await execQueryForList(fetchQuery);
    }

    const {data, ...others} = useQuery(queryKey, queryFn);

    useEffect(() => {
        if (!data) return;
        const events = {};
        data.forEach(event => {
            if (!(event.remainingSeatCount > 0)) return;

            const startingDate = event.startingDate?.toDate();
            if (!startingDate) return;

            const eventDay = dateToDDMMYYYY(startingDate);
            let dayGroup = events[eventDay];
            if (!dayGroup) {
                dayGroup = {
                    [EventTime.MORNING.name]: [],
                    [EventTime.AFTERNOON.name]: [],
                }
                events[eventDay] = dayGroup
            }

            const eventTime = startingDate.getHours() <= 12 ? EventTime.MORNING : EventTime.AFTERNOON;
            dayGroup[eventTime.name].push(event);
        });

        const availableDays = Object.keys(events);
        setQueryData(queryData => ({...queryData, events, availableDays}));
    }, [data, setQueryData]);

    const setDeparture = useCallback((departure) => {
        setQueryData(queryData => {
            const {departure: _departure} = queryData;
            departure = _departure === departure ? null : departure;
            return {...queryData, departure}
        });
    }, [setQueryData]);

    const setDay = useCallback((day) => {
        setQueryData(queryData => {
            const {day: _day} = queryData;
            day = _day === day ? null : day;
            return {...queryData, day};
        });
    }, [setQueryData]);

    const setTime = useCallback((time) => {
        setQueryData(queryData => {
            const {time: _time} = queryData;
            time = _time === time ? null : time;
            return {...queryData, time};
        });
    }, [setQueryData]);


    const filteredEvents = useMemo(() => {
        if (!day || !time || !events[day]) return [];

        return events[day][time.name];
    }, [events, day, time]);

    return {...queryData, ...others, filteredEvents, setDeparture, setDay, setTime}
}

export const useSearchBackofficePendingEvents = () => {
    const [queryData, setQueryData] = useRecoilState(backofficePendingEventState);
    const {departure, day, time, events} = queryData;

    const queryKey = [Event.colNamePending, "search", departure];

    const queryFn = async () => {
        if (!departure) return [];

        const fetchQuery = _query(
            pendingService.colRef,
            where(Event.departure, "==", departure.id),
        );
        return await execQueryForList(fetchQuery);
    }

    const {data, ...others} = useQuery(queryKey, queryFn);

    useEffect(() => {
        if (!data) return;
        const events = {};
        data.forEach(event => {
            const startingDate = event.startingDate?.toDate();
            if (!startingDate) return;

            const eventDay = dateToDDMMYYYY(startingDate);
            let dayGroup = events[eventDay];
            if (!dayGroup) {
                dayGroup = {
                    [EventTime.MORNING.name]: [],
                    [EventTime.AFTERNOON.name]: [],
                }
                events[eventDay] = dayGroup
            }

            const eventTime = startingDate.getHours() <= 12 ? EventTime.MORNING : EventTime.AFTERNOON;
            dayGroup[eventTime.name].push(event);
        });

        const availableDays = Object.keys(events);
        setQueryData(queryData => ({...queryData, events, availableDays}));
    }, [data, setQueryData]);

    const setDeparture = useCallback((departure) => {
        setQueryData(queryData => {
            const {departure: _departure} = queryData;
            departure = _departure === departure ? null : departure;
            return {...queryData, departure}
        });
    }, [setQueryData]);

    const setDay = useCallback((day) => {
        setQueryData(queryData => {
            const {day: _day} = queryData;
            day = _day === day ? null : day;
            return {...queryData, day};
        });
    }, [setQueryData]);

    const setTime = useCallback((time) => {
        setQueryData(queryData => {
            const {time: _time} = queryData;
            time = _time === time ? null : time;
            return {...queryData, time};
        });
    }, [setQueryData]);


    const filteredEvents = useMemo(() => {
        if (!day || !time || !events[day]) return [];

        return events[day][time.name];
    }, [events, day, time]);

    return {...queryData, ...others, filteredEvents, setDeparture, setDay, setTime}
}

export const useSearchBackofficeValidatedEvents = () => {
    const [queryData, setQueryData] = useRecoilState(backofficeValidatedEventState);
    const {departure, day, time, events} = queryData;

    console.log({departure, day, time})

    const queryKey = [Event.colNameValidated, "backoffice.validated.search", departure];

    const queryFn = async () => {
        if (!departure) return [];

        const fetchQuery = _query(
            validatedService.colRef,
            where(Event.departure, "==", departure.id),
            // where(Event.startingDate, ">=", new Date()),
            // where(Event.remainingSeatCount, "==", 0)
        );
        return await execQueryForList(fetchQuery);
    }

    const {data, ...others} = useQuery(queryKey, queryFn);

    useEffect(() => {
        if (!data) return;
        const events = {};
        data.forEach(event => {
            if (!(event.remainingSeatCount > 0)) return;

            const startingDate = event.startingDate?.toDate();
            if (!startingDate) return;

            const eventDay = dateToDDMMYYYY(startingDate);
            let dayGroup = events[eventDay];
            if (!dayGroup) {
                dayGroup = {
                    [EventTime.MORNING.name]: [],
                    [EventTime.AFTERNOON.name]: [],
                }
                events[eventDay] = dayGroup
            }

            const eventTime = startingDate.getHours() <= 12 ? EventTime.MORNING : EventTime.AFTERNOON;
            dayGroup[eventTime.name].push(event);
        });

        const availableDays = Object.keys(events);
        setQueryData(queryData => ({...queryData, events, availableDays}));
    }, [data, setQueryData]);

    const setDeparture = useCallback((departure) => {
        setQueryData(queryData => {
            const {departure: _departure} = queryData;
            departure = _departure === departure ? null : departure;
            return {...queryData, departure}
        });
    }, [setQueryData]);

    const setDay = useCallback((day) => {
        setQueryData(queryData => {
            const {day: _day} = queryData;
            day = _day === day ? null : day;
            return {...queryData, day};
        });
    }, [setQueryData]);

    const setTime = useCallback((time) => {
        setQueryData(queryData => {
            const {time: _time} = queryData;
            time = _time === time ? null : time;
            return {...queryData, time};
        });
    }, [setQueryData]);


    const filteredEvents = useMemo(() => {
        if (!day || !time || !events[day]) return [];

        return events[day][time.name];
    }, [events, day, time]);

    return {...queryData, ...others, filteredEvents, setDeparture, setDay, setTime}
}

export const useSearchBackofficePastEvents = () => {

    const [queryData, setQueryData] = useRecoilState(backofficePastEventState);
    const {departure, day, time, events} = queryData;

    const queryKey = [Event.colNameValidated, "backoffice.past.search", departure];

    const queryFn = async () => {
        if (!departure) return [];

        const fetchQuery = _query(
            validatedService.colRef,
            where(Event.departure, "==", departure.id),
            where(Event.startingDate, "<", new Date())
        );

        // fetchQuery = _query(fetchQuery, where(Event.remainingSeatCount, ">", 0));

        return await execQueryForList(fetchQuery);
    }

    const {data, ...others} = useQuery(queryKey, queryFn);

    useEffect(() => {
        if (!data) return;
        const events = {};
        data.forEach(event => {
            const startingDate = event.startingDate?.toDate();
            if (!startingDate) return;

            const eventDay = dateToDDMMYYYY(startingDate);
            let dayGroup = events[eventDay];
            if (!dayGroup) {
                dayGroup = {
                    [EventTime.MORNING.name]: [],
                    [EventTime.AFTERNOON.name]: [],
                }
                events[eventDay] = dayGroup
            }

            const eventTime = startingDate.getHours() <= 12 ? EventTime.MORNING : EventTime.AFTERNOON;
            dayGroup[eventTime.name].push(event);
        });

        const availableDays = Object.keys(events);
        setQueryData(queryData => ({...queryData, events, availableDays}));
    }, [data, setQueryData]);

    const setDeparture = useCallback((departure) => {
        setQueryData(queryData => {
            const {departure: _departure} = queryData;
            departure = _departure === departure ? null : departure;
            return {...queryData, departure}
        });
    }, [setQueryData]);

    const setDay = useCallback((day) => {
        setQueryData(queryData => {
            const {day: _day} = queryData;
            day = _day === day ? null : day;
            return {...queryData, day};
        });
    }, [setQueryData]);

    const setTime = useCallback((time) => {
        setQueryData(queryData => {
            const {time: _time} = queryData;
            time = _time === time ? null : time;
            return {...queryData, time};
        });
    }, [setQueryData]);


    const filteredEvents = useMemo(() => {
        if (!day || !time || !events[day]) return [];

        return events[day][time.name];
    }, [events, day, time]);

    return {...queryData, ...others, filteredEvents, setDeparture, setDay, setTime}
}


const mapOffers = (offers) => {
    return offers.map(data => data.status === TicketOfferStates.new || !data.status ? {
        ...data,
        status: TicketOfferStates.activated
    } : data);
}

export const createEvent = async (entity) => {
    if (!entity) return ko("Impossible de créer un événement nul");
    if (!entity?.ticketOffers?.length) return ko("Impossible de créer un événement sans offres");
    if (!entity?.images?.length) return ko("Impossible de créer un événement sans images");
    try {
        const ticketOffers = mapOffers(entity.ticketOffers);
        const {images: _images, ...others} = entity;
        const {status, entity: images, message} = await uploadFiles(Event.folderName, _images);

        if (!status) ko(message);
        const ref = await pendingService.create(auditorCreate({...others, ticketOffers, images}));
        await createLog();

        return ok(ref);
    } catch (e) {
        return ko("Erreur lors de la création de l'événement");
    }
}

export const updateEvent = async ({ref, entity}) => {
    if (!entity) return ("Impossible de modifier un évènement nul");
    if (!entity.ticketOffers?.length) return ("Impossible de modifier un évènement sans offres");
    if (!entity.images?.length) return ("Impossible de modifier un évènement sans images");
    try {
        const ticketOffers = mapOffers(entity.ticketOffers);

        const {images: _images, ...others} = entity;
        const docRef = documentRef(ref);

        const {status, entity: images, message} = await uploadFiles(Event.folderName, _images);

        if (!status) return ko(message);

        await setDoc(docRef, {...others, ticketOffers, images});
        return ok(docRef);
    } catch (e) {
        return ko("Erreur lors de la mise a jour du événement");
    }
}

export const addEventImage = async (event, image) => {
    if (!event) return ko("Cet évènement est null");
    if (!image) return ko("Cette image est nulle");

    const {status, entity: imageUrl, message} = await uploadFile(Event.folderName, image.file);
    if (!status) return ko(message);

    const images = [...event.images, imageUrl];
    await _updateField(documentRef(event.ref), {images});

    return ok(event.ref, "Image ajoutée avec succès");
}

export const deleteEventImage = async (event, imageUrl) => {
    if (!event) return ko("Cet évènement est null");
    if (!imageUrl) return ko("Cette image est nulle");

    const images = event.images.filter(_imageUrl => _imageUrl !== imageUrl);
    await _updateField(documentRef(event.ref), {images});

    return ok(event.ref, "Image enlevée avec succès");
}

export const useFindPendingById = (id) => useFindByRef(Event.colNamePending, id);

export const useFindValidatedById = (id) => useFindByRef(Event.colNameValidated, id);

export const useFindByRef = (colName, id) => {
    const ref = `${colName}/${id}`;
    const queryKey = [colName, "by.id", ref];
    const queryFn = () => {
        if (!ref) return null;
        return findByRef(ref);
    }
    return useQuery(queryKey, queryFn, {});
}

export const useFindEventParticipants = (eventId) => {
    const queryKey = ["FIND_ALL_EVENT_PARTICIPANT", eventId];
    const queryFn = async () => {
        const q = _query(collectionGroupRef("tickets"));
        return await execQueryForList(q, where("eventId", "==", eventId))
    };
    return useQuery(queryKey, queryFn, {});
}

export async function validateEvent(eventId) {
    try {
        if (!eventId) return ko("Impossible de valider un évènement nul");

        const event = await pendingService.findById(eventId);

        return await runTransaction(async (transaction) => {
            try {
                const ref = validatedService.newDocRef();
                transaction.set(ref, auditorCreate({
                    ...event,
                    ref: ref.path,
                    remainingSeatCount: event.seatCount,
                    id: ref.id
                }));
                transaction.delete(pendingService.docRef(eventId));
                return ok(ref.id, "Evènement validée avec succès");
            } catch (e) {
                return ko("Erreur lors de la validation de l'évènement");
            }
        });
    } catch (e) {
        return ko("Erreur lors de la validation de l'évènement");
    }
}

export const useInvalidateQueries = () => useInvalidateQueriesByRootName(Event.colNamePending);


// export const changeUrl = async (event) => {
//     const docs = await execQueryForList(_query(validatedService.colRef));
//     for (const event1 of [...docs]) {
//             console.log(event1);
//             const {ref, images} = event1;
//             const changedImages = images.map(img => {
//                 console.log("img === ", img);
//                 const splitter = "?alt=media&token=";
//                 const [first, second] = img.split(splitter);
//                 return `${first}_200x200${splitter}${second}`;
//             });
//             console.log("changedImages === ", changedImages);
//             await updateDocByPath(ref, {imageUrls: images, images: changedImages});
//         }
// }

