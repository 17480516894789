import {isString} from "formik";
import {v4 as uuidV4} from "uuid";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";

import {app} from "./@firebase";
import {ko, ok} from "../../../utils/service.utils";

export const storage = getStorage(app);

export const uploadFiles = async (folder, files) => {
    try {
        const _folder = (folder ? `${folder}/` : '');
        const fileLinks = files
            .map(async (file) => {

                if (isString(file)) return {downloadURL: file}

                const filePath = `${_folder}${uuidV4()}`;
                const fileRef = ref(storage, filePath);

                await uploadBytes(fileRef, file);
                const downloadURL = await getDownloadURL(fileRef);
                return {downloadURL};
            });
        const downloadURLs = (await Promise.all(fileLinks)).map(({downloadURL}) => downloadURL);
        return ok(downloadURLs);
    } catch (e) {
        console.log('e ==> ', e)
        return ko("Erreur lors du chargement du fichier");
    }
}

export const uploadFile = async (folder, file) => {
    try {
        console.log("uploadFile === ", file);
        if (isString(file)) return ok(file);
        const _folder = (folder ? `${folder}/` : '');
        const filePath = `${_folder}${uuidV4()}`;
        const fileRef = ref(storage, filePath);

        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        return ok(downloadURL);
    } catch (e) {
        console.log('e ==> ', e)
        return ko("Erreur lors du chargement du fichier");
    }
}

// const downloadFile = (data, filename) => {
//     const linkElement = document.createElement('a');
//     linkElement.setAttribute('download', filename);
//     const href = URL.createObjectURL(data);
//     linkElement.href = href;
//     linkElement.setAttribute('target', '_blank');
//     linkElement.click();
//     URL.revokeObjectURL(href);
// }
//
// export const donwloadFileFromUrl = () => {
//     const url = 'https://firebasestorage.googleapis.com/v0/b/bamako-city-tour.appspot.com/o/produstc%2Fb684fef3-a62c-4443-8a7e-9338207f9a29?alt=media&token=d3e96d4a-8fa4-468d-ac67-7c162c7df3f5';
//     const xhr = new XMLHttpRequest();
//     xhr.responseType = 'blob';
//     xhr.onload = (event) => {
//         const blob = xhr.response;
//         downloadFile(blob, 'sng.jpg');
//     };
//     xhr.open('GET', url);
//     xhr.send();
// }

export const deleteFile = async (fileFullPath) => {
    const fileRef = ref(storage, fileFullPath);
    await deleteObject(fileRef);
}

